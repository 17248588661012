.link-card{ margin: 1rem auto; }

.card{
  &-text{
    font-family: "Roboto Bold", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    color: $text-color-white;
    line-height: 1;
    margin-bottom: 0.5em;
    font-size: .9em;
    letter-spacing: 1px
  }
  &-home{
    height: 150px;
    max-width: $max-width;
    padding: 0;
  
    color: $text-color-white;
    border: 0px;
    height: 200px;
    .btn{
      border: 0px;
      border-radius: 0px;
    }
    .card-header:first-child {
      border-radius: 0px;
    }
    i{
      right: 10px;
    }
  }
  &-body{
    padding: 1.5rem;
    img{
      margin: 1rem 0;
    }
    .img-advice{
      margin: 0;
      height: 150px;
      width: auto;
    }
  }
  &-header{
    font-family: $roboto-medium;
    i{
      position: absolute;
      margin: .3rem .2rem .3rem 1.2rem;
    }
  }
  &-icon{
    margin: auto;
    border-radius: 100px;
    position: absolute;
    left: 1rem;
    top: .7rem;
    width: 80px;
    height: 80px;
    img{
      top: -25px;
      position: relative;
    }
  }
}

.card-colaboradores {
  .card-header{
    background-color: $employees;
  }
  .card-body{
    background-color: $employees;
    border-bottom: 1px solid #eee;
  }
  .card-icon{
    padding: 17% 0% 0% 4%;
    background-color: $employees;
  }
}
.card-buenas-practicas {
  .card-header{
    background-color: $good-practices;
  }
  .card-body{
    background-color: $good-practices;
    border-bottom: 1px solid #eee;
  }
  .card-icon{
    padding: 12% 0% 0% 0%;
    background-color: $good-practices;
  }
}
.card-denuncia {
  .card-header{
    background-color: $complaint;
  }
  .card-body{
    background-color: $complaint;
    border-bottom: 1px solid #eee;
  }
  .card-icon{
    padding: 16% 0% 0% 0%;
    background-color: $complaint;
  }
}
.card-proveedores {
  .card-header{
    background-color: $providers;
  }
  .card-body{
    background-color: $providers;
    border-bottom: 1px solid #eee;
  }
  .card-icon{
    padding: 16% 0% 0% 1%;
    background-color: $providers;
  }
}
.card-stakeholders {
  .card-header{
    background-color: $stakeholders;
  }
  .card-body{
    background-color: $stakeholders;
    border-bottom: 1px solid #eee;
  }
  .card-icon{
    padding: 14% 0% 0% 0%;
    background-color: $stakeholders;
  }
}
  
.card-buenas-practicas{
  &:hover, &:active {
    .card-icon{
      background-color: $good-practices;
      opacity: $hover-opacity;  
    }
    .card-body, .card-header{
      background-color: $good-practices;
      opacity: $hover-opacity;  
    }
  }
}
.card-denuncia{
  &:hover, &:active {
    .card-icon{
      background-color: $complaint;
      opacity: $hover-opacity;  
    }
    .card-body, .card-header{
      background-color: $complaint;
      opacity: $hover-opacity;  
    }
  }
}
.card-colaboradores{
  &:hover, &:active {
    .card-icon{
      background-color: $employees;
      opacity: $hover-opacity;  
    }
    .card-body, .card-header{
      background-color: $employees;
      opacity: $hover-opacity;  
    }
  }
}
.card-proveedores{
  &:hover, &:active {
    .card-icon{
      background-color: $providers;
      opacity: $hover-opacity;  
    }
    .card-body, .card-header{
      background-color: $providers;
      opacity: $hover-opacity;  
    }
  }
}
.card-stakeholders{
  &:hover, &:active {
    .card-icon{
      background-color: $stakeholders;
      opacity: $hover-opacity;  
    }
    .card-body, .card-header{
      background-color: $stakeholders;
      opacity: $hover-opacity;  
    }
  }
}
