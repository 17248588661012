// Body
$body-bg: #f8fafc;
$text-color-default: #212529;
$text-color-white: #f8fafc;
$hover-opacity: 0.75;
$inactive: #b3b3b3;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$logo-1: #35a7b0;
$logo-2: #04afa7;
$employees: #04afa7;
$complaint: #b41546;
$good-practices: #007c51;
$providers: #234aa5;
$stakeholders: #656565;
$help: #b41546;
$link: #3490dc;

// width
$carousel-width: 350px;
$max-width: 300px;
