.btn, .el-button{
  border-radius: 0 !important;
  cursor: pointer;
}

.btn-position, .btn-position:hover{
	color: $text-color-white;
	font-family: $roboto-bold;
	border-radius: 0;
	margin-right: 2px;
	height: 37px;
}
.btn-colaboradores{
	background-color: $employees;
  border-color: $employees;
  &:hover{
    background-color: $employees;
    opacity: $hover-opacity;
    border-color: $employees;
  }
}
.btn-buenas-practicas{
	background-color: $good-practices;
  border-color: $good-practices;
  &:hover{
    background-color: $good-practices;
    opacity: $hover-opacity;
    border-color: $good-practices;
  }
}
.btn-denuncia{
	background-color: $complaint;
  border-color: $complaint;
  &:hover{
    background-color: $complaint;
    opacity: $hover-opacity;
    border-color: $complaint;
  }
}
.btn-proveedores{
	background-color: $providers;
  border-color: $providers;
  &:hover{
    background-color: $providers;
    opacity: $hover-opacity;
    border-color: $providers;
  }
}
.btn-stakeholders{
	background-color: $stakeholders;
  border-color: $stakeholders;
  &:hover{
    background-color: $stakeholders;
    opacity: $hover-opacity;
    border-color: $stakeholders;
  }
}
.btn-help{
	position:fixed;
	width:70px;
	height:70px;
	bottom:10px;
	right:10px;
	color: $text-color-white;
	border-radius:50px !important;
	text-align:center;
	background-color: $help;
  border: 0px;
  border-color: 1px solid white;
  &:hover{
    background-color:$help;
    opacity: $hover-opacity;
    border-color:$help;
  }
}
.btn-reportar{
	position:fixed;
	width:70px;
	height:70px;
	bottom:10px;
	left:10px;
	color:#FFF;
	border-radius:50px !important;
	text-align:center;
  background-color:orange;
  padding-top: .75rem;
  border: 0px;
  &:hover{
    background-color:#F45D6F;
    border-color:#F45D6F;
  }
}

.btn-estado-denuncia{
	background-color: $logo-2;
	color: $text-color-white;
	font-family: $roboto-bold;
  border: 0px;
  &:hover{
    background-color: $logo-2;
    opacity: $hover-opacity;
    color: white;
    font-family: $roboto-bold;
  }
}
@media (max-width: 320px) {
	.btn-position{
		font-size: 0.8rem;
	}
}

@media (max-width: 375px) {
	.btn-help {
		bottom: 10px;
	}
  .buttons-csv{
    width: 100%;
    margin-bottom: 1em;
  }
}

