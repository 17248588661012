.box-carousel{
  width: 100%;
  max-width: $max-width;
  .card{
    width: 100%;
    &-body{
      padding: 0;
    }
  }
}

.carousel {
  width: 100%;
  max-width: 300px;
  margin: auto;
  &-item{
    width: 100%;
    height: 200px;
    max-width: $max-width;
    img{
      margin-top: 1.5rem;
    }
    .card-body{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &-inner {
    margin: 1rem auto;
    max-height: 200px;
    height: 200px;

    hr {
      border: 1px solid #04afa7;
    }
  }
  &-icon{
    padding-top:1rem;
  }
  &-init {
    padding: 1rem 2rem 0rem 2rem;
    border: 2px solid #0C59A5;
  }
  &-colaboradores &-inner{
    padding-top: 5px;
    padding-bottom: 2rem;
    padding-right:1.0rem !important;
    padding-left:1.0rem !important;
  }
  &-img{
    width: 100%;
  }
  
  .color-logo-1{ color: $logo-1 }
  .color-logo-2{ color: $logo-2 }
}

@media (max-width: 320px) {
	.box-carousel{
		width: 260px;
	}
	.carousel-img{
		width: 250px;
	}
	.carousel-home {
    .box-carousel{
      width: 250px;
    }
    .carousel-inner {
      margin: 1.5em;
      padding-top: 5px;
      padding-bottom: 2rem;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }
  }
}
@media (max-width: 375px) {
	.carousel-colaboradores {
    .carousel-inner {
      padding-right:0px !important;
      padding-left:0px !important;
    }
  }

}
